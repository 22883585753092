<template>
	<section class="grid grid-cols-1 gap-2 lg:grid-cols-2">
		<BaseCard class="col-span-full">
			<template #header>
				<BaseButtonGroup
					id="compliance-checker-type"
					v-model="complianceType"
					:buttons="textTypes"
					:full-width="false"
					:show-default-option="false"
					legend="Text type"
					show-label
				/>
			</template>

			<header class="text-xl font-bold text-sf-charcoal dark:text-gray-100">
				Allowed Terms (comma separated, space matters)
			</header>
			<TextFieldInput
				v-model="allowedTerms"
				label="Use this to emulate allowed terms. ie terms that are ignored."
			/>

			<ComplianceChecker
				key="compliance-checker-checker"
				v-model="text"
				:confirm-changes="false"
				:domain="DOMAIN"
				:name="currentTextType.text + ' Compliance Checker Checker'"
				:product-type="PRODUCT_TYPE"
				:required="false"
				:show-disclaimer="false"
				:type="complianceType"
				:allowed-terms="allowedTermsList"
				class="col-span-full"
				height="200px"
				@update:compliance-response="complianceResult = $event"
				@update:compliance-text="text = $event"
			/>
		</BaseCard>
		<div class="flex h-full flex-col gap-2">
			<BaseCard class="h-2/3">
				<template #header>
					<span> Raw Text </span>
					<BaseButton class="w-fit" color="primary" @click="copyText(text)">
						Copy
					</BaseButton>
				</template>
				<blockquote
					class="w-full whitespace-pre-wrap rounded-md bg-gray-100 p-4 dark:bg-gray-700 dark:text-gray-100"
				>
					{{ text }}
				</blockquote>
			</BaseCard>
			<BaseCard class="h-1/3">
				<template #header> Manually Entered Allowed terms </template>
				<ul>
					<li v-for="term in allowedTermsList" :key="term">
						<blockquote
							class="mb-2 w-full grow whitespace-pre-wrap rounded-md bg-gray-100 p-1 dark:bg-gray-700 dark:text-gray-100"
						>
							"{{ term }}"
						</blockquote>
					</li>
				</ul>
			</BaseCard>
		</div>
		<BaseCard class="max-h-[34rem] overflow-y-auto">
			<template #header>
				<span> Raw MySFdomain API Response </span>
				<BaseButton
					class="w-fit"
					color="primary"
					@click="copyText(formattedComplianceResult)"
				>
					Copy
				</BaseButton>
			</template>

			<blockquote
				class="w-full whitespace-pre-wrap rounded-md bg-gray-100 p-4 dark:bg-gray-700"
			>
				{{ formattedComplianceResult }}
			</blockquote>
		</BaseCard>
	</section>
</template>

<script setup>
import { ref, computed } from 'vue';

import BaseCard from '@/components/ui/BaseCard.vue';
import BaseButtonGroup from '@/components/ui/BaseButtonGroup.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import TextFieldInput from '@/components/ui/TextFieldInput';
import ComplianceChecker from '@/components/MXEditor/ComplianceChecker';

const textTypes = [
	{ text: 'Rich Text', value: 'richtext' },
	{ text: 'Plain Text', value: 'text' },
];
const DOMAIN = 'm2foryou.com';
const PRODUCT_TYPE = 'm2';

const text = ref('');

const allowedTerms = ref('');
const allowedTermsList = computed(() => allowedTerms.value.split(',').filter(v => v != ''));

const complianceResult = ref('');
const complianceType = ref('richtext');
const currentTextType = computed(() => textTypes.find(type => type.value === complianceType.value));
const formattedComplianceResult = computed(() => JSON.stringify(complianceResult.value, null, 2));

function copyText(text) {
	navigator.clipboard.writeText(text);
}
</script>
