export const redirectOnlyForAgentInternText = `As an Agent Intern, your agent domain site will redirect to www.statefarm.com until you appoint. Because of this, upgrading to M1 or M2 will be disabled until that time.`;

export const productTypes = {
	m2: {
		initials: 'M2',
		badgeColor: 'blue',
		titleShort: 'M2',
		titleLong: 'M2 - Advanced Marketing Domain',
		listHeading: 'M2 - Advanced Marketing Domains',
		description:
			'Our most advanced multi-page customizable marketing site with upgraded designs and layout.',
		highlight: 'The New Hotness!',
		allowIntern: false,
		features: [
			'Includes all <b>M1</b> features plus…',
			'A true multi-page website',
			'Extremely fast performance scores',
			'Multiple homepage layout options',
			'Integrated Google reviews with Call-to-Action links',
			'Individual Line of Business pages with agent-specific and localized pre-written content',
			'Integrated job listing details with Apply Now options',
			'Link individual pages to your online marketing campaigns',
			'Satellite/CSSO/Second Office capable',
			'Built-in industry leading SEO techniques',
			'SSL secured (https)',
		],
		price: 54,
		needsParkedTarget: false,
		old_domain_type: 'm2',
	},
	m1: {
		initials: 'M1',
		badgeColor: 'orange',
		titleShort: 'M1',
		titleLong: 'M1 - Entry Level Marketing Domain',
		description: 'A highly customizable single-page marketing website.',
		listHeading: 'M1 - Entry Level Marketing Domains',
		highlight: false,
		allowIntern: false,
		features: [
			'Single page website',
			'Great performance scores with a modern design',
			'Editable content for agent and team members',
			'Social feeds',
			'Job listings',
			'Unlimited free email redirects',
			'SSL secured (https)',
		],
		price: 29,
		needsParkedTarget: false,
		old_domain_type: 'm1',
	},
	sf_microsite: {
		initials: 'PM',
		badgeColor: 'red',
		titleShort: 'Proxied SF Microsite',
		titleLong: 'Proxied SF Microsite',
		listHeading: 'Proxied SF Microsites',
		description: 'A Secure Proxied Domain will mirror the content of your SF Microsite.',
		highlight: false,
		allowIntern: true,
		features: [
			'Creates a "mirrored" copy of your SF Corporate Microsite',
			'Approved for SEM use with Quote Landing Pages',
			'Unlimited Free E-Mail redirects',
			'SSL Secured (https)',
		],
		price: 19,
		needsParkedTarget: false,
		old_domain_type: 'proxy',
	},
	parked: {
		initials: 'PK',
		badgeColor: 'green',
		titleShort: 'Parked Domain Redirect',
		titleLong: 'Parked Domain Redirect',
		listHeading: 'Parked Domain Redirects',
		description:
			'A Secure Parked Domain will redirect all visitors to one of your secure domains. Email Redirects are $1/mo. each',
		highlight: false,
		allowIntern: true,
		features: [
			'Buy variations of domain names to protect your brand',
			'Redirect visitors to a Proxied, M1, or M2 domain',
			'E-Mail redirects available for an additional $1/month each',
			'SSL Secured (https)',
		],
		price: 13,
		needsParkedTarget: true,
		old_domain_type: 'redirect',
	},
	old_parked: {
		initials: 'PK',
		badgeColor: 'white',
		titleShort: 'Old Parked Domain Redirect',
		titleLong: 'Parked Domain Redirect',
		subtitle: '',
		retired: true,
		listHeading: null,
		description:
			'A Secure Parked Domain will redirect all visitors to one of your secure domains for $13/mo. Email Redirects are $1/mo. each.',
	},
};
