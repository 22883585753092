<template>
	<figure
		class="grid aspect-square shrink-0 grow-0 place-items-center overflow-hidden rounded-full border-2 border-solid bg-orange-500 transition-[height,_width] duration-300"
		:class="
			{
				white: 'border-white',
				gray: 'border-gray-500',
			}[color]
		"
		:style="{ width: widthString, height: widthString }"
	>
		<FAIcon
			v-if="error || !loaded"
			:size="placeholderSize"
			icon="user"
			class="h-1/2 w-1/2 text-white"
		/>

		<img
			v-if="!error"
			class="transition-opacity duration-300"
			:class="loaded ? 'opacity-100' : 'opacity-0'"
			v-bind="{ position }"
			:src="imageUrl"
			:alt="alt"
			@load="loaded = true"
			@error="error = true"
		/>
	</figure>
</template>

<script setup>
import { computed, ref, watch } from 'vue';

const props = defineProps({
	position: { type: String, default: 'top' },
	width: { type: [String, Number], default: 'auto' },
	alt: { type: String, default: '' },
	color: { type: String, default: 'gray' },
	placeholderSize: { type: String, default: '2xl' },
	// eslint-disable-next-line vue/prop-name-casing
	associate_id: { type: String, default: undefined },
	avatar: { type: Boolean, default: true },
	thumbnail: { type: Boolean, default: true },
});

const loaded = ref(false);
const error = ref(false);

const TEAM_JOHNNY = {
	'0XDEADBEEFD':
		'https://storage.googleapis.com/static.mirus.io/images/etc/superflyagentguy_com_avatar.jpeg',
	JNDF1NSBLDJ: 'https://randomuser.me/api/portraits/men/48.jpg',
	RQBXLIF0TA0: 'https://randomuser.me/api/portraits/women/28.jpg',
	'9G1YLUI8RFD': 'https://randomuser.me/api/portraits/women/65.jpg',
	UHO7NNNL8LP: 'https://randomuser.me/api/portraits/women/96.jpg',
	'7YLZCQOAKUG': 'https://randomuser.me/api/portraits/women/39.jpg',
	AZBHNZ4PJKC: 'https://randomuser.me/api/portraits/men/10.jpg',
	B18ZOVGPZIE: 'https://randomuser.me/api/portraits/women/25.jpg',
	G1DQZX1ONIG: 'https://randomuser.me/api/portraits/women/57.jpg',
};

const imageUrl = computed(() => {
	if (Object.keys(TEAM_JOHNNY).includes(props.associate_id)) {
		return TEAM_JOHNNY[props.associate_id];
	} else {
		const first_letter = props.associate_id?.substring(0, 1) ?? '';
		const filename = `formalColor${props.thumbnail ? 'Thumb' : 'Full'}.jpg`;
		return `https://ac2.st8fm.com/associate-photos/${first_letter}/${props.associate_id}/${filename}`;
	}
});
const widthString = computed(() => {
	switch (typeof props.width) {
		case 'number':
			return `${props.width}px`;
		case 'string':
		default:
			return isNaN(Number(props.width)) ? props.width : `${Number(props.width)}px`;
	}
});

watch(imageUrl, newImageUrl => {
	if (newImageUrl) {
		error.value = false;
		loaded.value = true;
	}
});
</script>
