<template>
	<div :class="{ absolute: !$slots.activator }" class="flex">
		<slot v-if="$slots.activator" name="activator" v-bind="{ openDialog, closeDialog }" />
		<dialog
			ref="dialog"
			:role="alert && 'alertdialog'"
			:style="{
				maxWidth: maxWidth ? maxWidth + 'px' : '95vw',
			}"
			:class="
				open
					? 'pointer-events-auto scale-100 opacity-100 backdrop:opacity-100'
					: 'pointer-events-none scale-90 opacity-0 backdrop:opacity-0'
			"
			class="max-h-[95vh] w-fit !rounded-xl bg-white shadow-2xl transition ease-in-out backdrop:bg-gray-900/50 backdrop:transition dark:bg-gray-800 dark:text-gray-100 dark:shadow-gray-950/85 dark:backdrop:bg-gray-950/85"
			:aria-labelledby="uid"
			:aria-describedby="ariaDescribedby"
			@click.stop="closeDialog"
			@keydown.escape="closeDialog"
		>
			<header
				v-if="$slots.header"
				class="sticky top-0 z-10 flex items-start justify-between gap-2 bg-white p-2 pl-4 text-2xl font-bold dark:bg-gray-800"
				@click.stop="false"
			>
				<span :id="uid">
					<slot name="header" />
				</span>
				<BaseButton
					v-if="!persistent"
					aria-label="close dialog"
					color="darkGray"
					text
					class="aspect-square"
					@click="closeDialog"
				>
					<FAIcon size="lg" icon="times" />
				</BaseButton>
			</header>

			<article
				:class="{
					'p-0': !dense && superDense,
					'p-2': dense && !superDense,
					'p-4': !dense && !superDense,
					'pb-0': $slots.actions,
					'pt-0': $slots.header,
				}"
				class="min-h-0 overflow-y-auto"
				@click.stop="false"
			>
				<slot v-bind="{ closeDialog }" />
			</article>

			<footer
				class="sticky bottom-0 z-10 flex flex-col bg-white dark:bg-gray-800"
				@click.stop="false"
			>
				<div
					v-if="$slots.actions"
					class="flex justify-end gap-2"
					:class="{
						'p-0': !dense && superDense,
						'p-2': dense && !superDense,
						'p-4': !dense && !superDense,
					}"
				>
					<slot name="actions" v-bind="{ closeDialog }" />
				</div>
				<div v-if="!persistent" class="relative -mt-2">
					<div
						class="rounded-md bg-gray-200 pb-2 pt-4 text-center text-xs dark:bg-gray-900"
					>
						press
						<kbd>Esc</kbd>
						to close this dialog
					</div>
					<div
						class="absolute top-0 h-2 w-full rounded-b-xl bg-white dark:bg-gray-800"
					></div>
				</div>
			</footer>
		</dialog>
	</div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';

import BaseButton from '@/components/ui/BaseButton.vue';

const props = defineProps({
	modelValue: { type: Boolean, default: false },
	maxWidth: { type: [Number, String], default: null },
	persistent: { type: Boolean, default: false },
	dense: { type: Boolean, default: false },
	superDense: { type: Boolean, default: false },
	ariaDescribedby: { type: String, default: null },
	alert: { type: Boolean, default: false },
});

const emit = defineEmits(['close', 'update:modelValue']);

const dialog = ref(null);
const open = ref(props.modelValue);
const uid = ref('dialog-heading-' + Math.random().toString(16).slice(2));

function openDialog() {
	open.value = true;
	dialog.value?.showModal();
	emit('update:modelValue', true);
	document.querySelector(':root').classList.add('overflow-hidden');
}

function closeDialog(force) {
	if (open.value) {
		emit('close');

		if (!props.persistent || force) {
			open.value = false;
			emit('update:modelValue', false);
			// allows the dialog backdrop to transition instead of disappearing immediately
			setTimeout(() => {
				dialog.value?.close();
				if (!document.querySelector('dialog[open]'))
					document.querySelector(':root').classList.remove('overflow-hidden');
			}, 150);
		}
	}
}

watch(
	() => props.modelValue,
	() => {
		if (props.modelValue !== open.value) {
			props.modelValue ? openDialog() : closeDialog(true);
		}
	},
	{ immediate: true }
);

defineExpose({ openDialog, closeDialog });

onMounted(() => {
	if (props.modelValue === true) {
		openDialog();
	}
});
</script>

<style scoped></style>
