<template>
	<div class="grid h-full grid-cols-1 grid-rows-[max-content_minmax(0,_1fr)]">
		<div class="sm:hidden">
			<label for="tabs" class="sr-only">Select a tab</label>
			<select
				:id="`${id}-tabs`"
				name="tabs"
				class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
				@input="event => updateActiveTab(event.target.value)"
			>
				<option
					v-for="tab in tabs"
					:key="tab.path"
					:selected="tab.path === localActiveTab"
					:value="tab.path"
				>
					{{ tab.name }}
				</option>
			</select>
		</div>

		<div
			role="tablist"
			class="sticky top-0 z-20 hidden h-auto items-center overflow-x-auto bg-white before:absolute before:bottom-0 before:h-0.5 before:w-full before:bg-gray-300 dark:bg-gray-900 sm:flex"
		>
			<div
				v-for="tab in tabs"
				:key="tab.path"
				class="text-md z-10 flex h-full w-fit items-center whitespace-nowrap border-b-[3px] border-solid text-center font-semibold ring-gray-800 transition-all"
				:class="[
					tab.path === localActiveTab
						? 'border-orange-600 text-gray-800 dark:text-orange-500'
						: 'border-transparent text-gray-400 hover:text-gray-700 dark:text-gray-200 dark:hover:text-gray-300',
				]"
				@keydown.left="previousTab()"
				@keydown.right="nextTab()"
			>
				<button
					:id="`${id}-tab-${tab.path}`"
					ref="tabButtons"
					role="tab"
					type="button"
					:aria-selected="tab.path === localActiveTab"
					:aria-controls="`${id}-panel-${tab.path}`"
					:tabindex="tab.path === localActiveTab ? 0 : -1"
					class="h-full px-4 py-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-orange-500"
					:class="{ 'pr-2': renamable || deletable }"
					@click="updateActiveTab(tab.path)"
				>
					{{ tab.name }}
				</button>
				<button
					v-if="renamable"
					:aria-label="`rename ${tab.name}`"
					:tabindex="tab.path === localActiveTab ? 0 : -1"
					class="grid h-6 w-6 place-items-center rounded-full transition-colors hover:bg-orange-200 hover:text-orange-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-orange-500"
					@click="$emit('rename', tab.path)"
				>
					<FAIcon icon="fa-pencil" size="sm" />
				</button>
				<button
					v-if="deletable"
					:aria-label="`delete ${tab.name}`"
					:tabindex="tab.path === localActiveTab ? 0 : -1"
					class="ml-1 grid h-6 w-6 place-items-center rounded-full transition-colors hover:bg-red-100 hover:text-red-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-red-500"
					@click="$emit('delete', tab.path)"
				>
					<FAIcon icon="fa-xmark" />
				</button>
			</div>
			<button
				v-if="addable"
				class="grid aspect-square h-full place-items-center border-b-[3px] border-transparent transition-colors hover:bg-gray-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-gray-500 dark:hover:bg-gray-700"
				@click="$emit('add')"
			>
				<FAIcon icon="fa-plus" size="sm" />
			</button>
		</div>

		<div
			v-for="tab in tabs"
			v-show="localActiveTab === tab.path"
			:id="`${id}-panel-${tab.path}`"
			:key="tab.path"
			:aria-labelledby="`${id}-tab-${tab.path}`"
		>
			<slot v-if="localActiveTab === tab.path" :name="`tabContent.${tab.path}`" />
		</div>
	</div>
</template>

<script setup>
import { onMounted, watch, ref, nextTick, computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const emit = defineEmits(['update:activeTab', 'delete', 'rename', 'add']);
const props = defineProps({
	id: { type: String, required: true },
	tabs: {
		type: Array,
		required: true,
		validator: value => value.every(tab => 'path' in tab && 'name' in tab),
	},
	activeTab: { type: [String, Number], required: true },
	renamable: { type: Boolean, default: false },
	deletable: { type: Boolean, default: false },
	addable: { type: Boolean, default: false },
});

const localActiveTab = ref(props.activeTab);
const queryParam = ref(`${props.id.replaceAll('-', '_')}_tab`);
const tabButtons = ref([]);

const activeTabButtonIndex = computed(() =>
	props.tabs.findIndex(tab => tab.path === localActiveTab.value)
);
const activeTabButton = computed(() => tabButtons.value[activeTabButtonIndex.value]);

function updateActiveTab(path) {
	localActiveTab.value = typeGuard(path);
	emit('update:activeTab', localActiveTab.value);
	if (route.query?.[queryParam.value] !== path) {
		router.replace({ query: { ...route.query, [queryParam.value]: localActiveTab.value } });
	}
}

function typeGuard(value) {
	if (typeof props.activeTab === 'number') {
		return parseInt(value);
	} else {
		return value;
	}
}

async function previousTab() {
	tabButtons.value.at(activeTabButtonIndex.value - 1).click();
	await nextTick();
	activeTabButton.value.focus();
}

async function nextTab() {
	tabButtons.value.at((activeTabButtonIndex.value + 1) % tabButtons.value.length).click();
	await nextTick();
	activeTabButton.value.focus();
}

onMounted(() => {
	updateActiveTab(route.query?.[queryParam.value] || props.activeTab);
});

watch(
	() => props.activeTab,
	newVal => {
		if (newVal !== localActiveTab.value) {
			updateActiveTab(props.activeTab);
		}
	}
);
</script>
