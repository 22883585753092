<template>
	<LoadingSpinner v-if="loading" text="Retrieving domain list..." class="mt-16 text-gray-200" />
	<div v-else class="rounded-md bg-white p-2 dark:bg-gray-800">
		<h3 class="py-2 text-2xl font-semibold text-gray-700 dark:text-gray-100">Active Domains</h3>
		<BaseExpansionList :items="movableDomains" focusable>
			<template #header="domain">
				<div class="flex items-center justify-between gap-2">
					<div class="flex items-center gap-2">
						<DomainProductTypeBadge small :domain="domain" />
						<span class="ml-2 text-base font-semibold">{{ domain.name }} </span>
					</div>
					<div
						class="mr-2 w-fit rounded-md border px-2 py-1 text-sm font-medium"
						:class="
							domain.active
								? 'border-green-300 bg-green-100 text-green-900 dark:border-green-100'
								: 'border-red-200 bg-red-100 text-red-800 dark:border-red-100'
						"
					>
						{{ domain.active ? 'Active' : 'Canceled' }}
					</div>
				</div>
			</template>
			<template #content="{ name, active }">
				<RegistrarCard v-if="active" v-bind="{ name }" />
				<div v-else class="bg-gray-100 p-2 dark:bg-gray-800">
					<div class="rounded-md bg-white p-2 text-center dark:bg-gray-700">
						This domain is no longer active, but may still be owned by Mirus. Try
						searching for the domain out at the Registrars.
					</div>
				</div>
			</template>
		</BaseExpansionList>
	</div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import RegistrarCard from '@/components/MirusControls/RegistrarCard';
import { storeToRefs } from 'pinia';
import useDomainsStore from '@/stores/agent/agent-domains';
import BaseExpansionList from '@/components/ui/BaseExpansionList.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import DomainProductTypeBadge from '@/components/domains/DomainProductTypeBadge';

const domainStore = useDomainsStore();
const { movableDomains } = storeToRefs(domainStore);

const loading = ref(true);

onMounted(async () => {
	if (!movableDomains.value) {
		await domainStore.getMovableDomains();
	}
	loading.value = false;
});
</script>
